<template>
  <div class="SectionTitle">
    <h3 class="o-default-h3">{{ titleContent }}</h3>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    titleContent: String
  }
};
</script>

<style lang="scss" scoped>
.SectionTitle {
  text-align: left;

  h3 {
    padding-bottom: 0;
  }
}
</style>
