<template>
  <div class="TextBlockDefault">
    <p class="o-default-body">{{ textContent }}</p>
  </div>
</template>

<script>
export default {
  name: 'TextBlockDefault',
  props: {
    textContent: String
  }
};
</script>

<style lang="scss" scoped>
.TextBlockDefault {
  margin-bottom: .8rem;
  text-align: justify;
  text-justify: inter-word;
}
</style>
