<template>
  <div class="SectionSubtitle">
    <h4 class="o-default-h4">{{ titleContent }}</h4>
  </div>
</template>

<script>
export default {
  name: 'SectionSubtitle',
  props: {
    titleContent: String
  }
};
</script>

<style lang="scss" scoped>
.SectionSubtitle {

  h4 {
    text-align: left;
    padding-bottom: 0;
    font-weight: 600;
  }
}
</style>
