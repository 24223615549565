<template>
  <div class="HelpAuctionsView">
    <div class="o-container-gray">
      <md-card class="md-layout-item">

        <md-card-header>
          <SectionTitle :titleContent="messages.title"/>
        </md-card-header>

        <md-card-content>

          <div class="o-container-subsection">
            <TextBlockDefault :textContent="messages.auctions0.content1"/>
            <TextBlockDefault :textContent="messages.auctions0.content2"/>
            <TextBlockDefault :textContent="messages.auctions0.content3"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.auctions1.subtitle"/>
            <TextBlockDefault :textContent="messages.auctions1.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.auctions2.subtitle"/>
            <TextBlockDefault :textContent="messages.auctions2.content1"/>
            <TextBlockDefault :textContent="messages.auctions2.content2"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.auctions3.subtitle"/>
            <md-list>
              <md-list-item>{{ messages.auctions3.listItem1 }}</md-list-item>
              <md-list-item>{{ messages.auctions3.listItem2 }}</md-list-item>
              <md-list-item>{{ messages.auctions3.listItem3 }}</md-list-item>
              <md-list-item>{{ messages.auctions3.listItem4 }}</md-list-item>
              <md-list-item>{{ messages.auctions3.listItem5 }}</md-list-item>
              <md-list-item>{{ messages.auctions3.listItem6 }}</md-list-item>
              <md-list-item>{{ messages.auctions3.listItem7 }}</md-list-item>
              <md-list-item>{{ messages.auctions3.listItem8 }}</md-list-item>
              <md-list-item>{{ messages.auctions3.listItem9 }}</md-list-item>
              <md-list-item>{{ messages.auctions3.listItem10 }}</md-list-item>
              <md-list-item>{{ messages.auctions3.listItem11 }}</md-list-item>
            </md-list>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.auctions4.subtitle"/>
            <TextBlockDefault :textContent="messages.auctions4.content1"/>
            <TextBlockDefault :textContent="messages.auctions4.content2"/>
            <TextBlockDefault :textContent="messages.auctions4.content3"/>
            <TextBlockDefault :textContent="messages.auctions4.content4"/>
            <md-table md-card v-model="extended">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell :md-label="messages.table.startingWith" md-sort-by="hour">{{ item.hour }}</md-table-cell>
                <md-table-cell :md-label="messages.table.minimumRaise" md-sort-by="amount">{{ item.amount }}</md-table-cell>
              </md-table-row>
            </md-table>
            <p class="o-dialog-content--strong">{{ $t('auctionView.extAuctionExample') }}</p>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.auctions5.subtitle"/>
            <TextBlockDefault :textContent="messages.auctions5.content1"/>
            <TextBlockDefault :textContent="messages.auctions5.content2"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.auctions6.subtitle"/>
            <TextBlockDefault :textContent="messages.auctions6.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.auctions7.subtitle"/>
            <TextBlockDefault :textContent="messages.auctions7.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.auctions8.subtitle"/>
            <TextBlockDefault :textContent="messages.auctions8.content1"/>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/components/util/SectionTitle';
import SectionSubtitle from '@/components/util/SectionSubtitle';
import TextBlockDefault from '@/components/util/TextBlockDefault';

export default {
  name: 'HelpAuctionsView',
  components: {
    SectionTitle,
    SectionSubtitle,
    TextBlockDefault
  },
  data() {
    return {
      messages: {
        title: this.$t('helpView.subtitle3'),
        auctions0: {
          content1: this.$t('helpView.auctions.auctions0.content1'),
          content2: this.$t('helpView.auctions.auctions0.content2'),
          content3: this.$t('helpView.auctions.auctions0.content3'),
        },
        auctions1: {
          subtitle: this.$t('helpView.auctions.auctions1.subtitle'),
          content1: this.$t('helpView.auctions.auctions1.content1'),
        },
        auctions2: {
          subtitle: this.$t('helpView.auctions.auctions2.subtitle'),
          content1: this.$t('helpView.auctions.auctions2.content1'),
          content2: this.$t('helpView.auctions.auctions2.content2'),
        },
        auctions3: {
          subtitle: this.$t('helpView.auctions.auctions3.subtitle'),
          listItem1: this.$t('helpView.auctions.auctions3.listItem1'),
          listItem2: this.$t('helpView.auctions.auctions3.listItem2'),
          listItem3: this.$t('helpView.auctions.auctions3.listItem3'),
          listItem4: this.$t('helpView.auctions.auctions3.listItem4'),
          listItem5: this.$t('helpView.auctions.auctions3.listItem5'),
          listItem6: this.$t('helpView.auctions.auctions3.listItem6'),
          listItem7: this.$t('helpView.auctions.auctions3.listItem7'),
          listItem8: this.$t('helpView.auctions.auctions3.listItem8'),
          listItem9: this.$t('helpView.auctions.auctions3.listItem9'),
          listItem10: this.$t('helpView.auctions.auctions3.listItem10'),
          listItem11: this.$t('helpView.auctions.auctions3.listItem11'),
        },
        auctions4: {
          subtitle: this.$t('helpView.auctions.auctions4.subtitle'),
          content1: this.$t('helpView.auctions.auctions4.content1'),
          content2: this.$t('helpView.auctions.auctions4.content2'),
          content3: this.$t('helpView.auctions.auctions4.content3'),
          content4: this.$t('helpView.auctions.auctions4.content4'),
        },
        auctions5: {
          subtitle: this.$t('helpView.auctions.auctions5.subtitle'),
          content1: this.$t('helpView.auctions.auctions5.content1'),
          content2: this.$t('helpView.auctions.auctions5.content2'),
        },
        auctions6: {
          subtitle: this.$t('helpView.auctions.auctions6.subtitle'),
          content1: this.$t('helpView.auctions.auctions6.content1'),
        },
        auctions7: {
          subtitle: this.$t('helpView.auctions.auctions7.subtitle'),
          content1: this.$t('helpView.auctions.auctions7.content1'),
        },
        auctions8: {
          subtitle: this.$t('helpView.auctions.auctions8.subtitle'),
          content1: this.$t('helpView.auctions.auctions8.content1'),
        },
        table: {
          startingWith: this.$t('auctionView.startingWith'),
          minimumRaise: this.$t('auctionView.minimumBid')
        }
      },
      extended: [
        {
          hour: "23:00",
          amount: "€10"
        },
        {
          hour: "23:05",
          amount: "€15"
        },
        {
          hour: "23:10",
          amount: "€20"
        },
        {
          hour: "23:15",
          amount: "€25"
        },
        {
          hour: "23:20",
          amount: "€30"
        },
        {
          hour: "23:25",
          amount: "€35"
        },
        {
          hour: "23:30",
          amount: "€40"
        },
        {
          hour: "23:35",
          amount: "€50"
        },
        {
          hour: "23:40",
          amount: "€75"
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.HelpAuctionsView {

  .md-list {
    margin: 0 .8rem 1.6rem .8rem;
  }

  .SectionSubtitle {
    margin-left: .8rem;
    padding: .8rem;
  }

  .TextBlockDefault {
    margin-left: .8rem;
    padding: .8rem;
  }
}
</style>
